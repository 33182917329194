const state = reactive({

})
export function useLayoutControl() {
    const inc_classes = [
        'col-span-1',
        'col-span-2',
        'col-span-3',
        'col-span-4',
        'col-span-5',
        'col-span-6',
        'col-span-7',
        'col-span-8',
        'col-span-9',
        'col-span-10',
        'col-span-11',
        'col-span-12',
        'col-span-13',
        'col-start-1',
        'col-start-2',
        'col-start-3',
        'col-start-4',
        'col-start-5',
        'col-start-6',
        'col-start-7',
        'col-start-8',
        'col-start-9',
        'col-start-10',
        'col-start-11',
        'col-end-1',
        'col-end-2',
        'col-end-3',
        'col-end-4',
        'col-end-5',
        'col-end-6',
        'col-end-7',
        'col-end-8',
        'col-end-9',
        'col-end-10',
        'col-end-11',
        'col-end-12',
        'col-end-13',
        'row-span-1',
        'row-span-2',
        'row-span-3',
        'row-span-4',
        'row-span-5',
        'row-span-6',
        'row-span-7',
        'row-span-8',
        'row-span-9',
        'row-span-10',
        'row-span-11',
        'row-span-12',
        'row-span-13',
        'row-start-1',
        'row-start-2',
        'row-start-3',
        'row-start-4',
        'row-start-5',
        'row-start-6',
        'row-start-7',
        'row-start-8',
        'row-start-9',
        'row-start-10',
        'row-start-11',
        'row-end-1',
        'row-end-2',
        'row-end-3',
        'row-end-4',
        'row-end-5',
        'row-end-6',
        'row-end-7',
        'row-end-8',
        'row-end-9',
        'row-end-10',
        'row-end-11',
        'row-end-12',
        'row-end-13',
        'justify-start',
        'justify-center',
        'justify-end',
        'content-start',
        'content-center',
        'content-end',
        'items-start',
        'items-center',
        'items-end',
        'self-start',
        'self-center',
        'self-end',
       
    ]
    function getAnimationSettings(blok: any) {
        
    }
    function getLayoutClasses(blok:any) {
        var layout = blok.layout || blok.layout_settings
        var animation_settings = blok.layout?.animation || false
        let layout_classes: any = []

        if (!layout) {
            // console.log("Layout is undefined for ", blok)
        }
        else {
            //  console.log(layout.a_enabled)
            layout?.a_enabled && animation_settings && layout_classes.push('animated animated--waiting')
           
            if (layout && layout.enabled) {
              
                layout_classes.push("using-layout")
                layout.align != '' && layout_classes.push(layout.align)
                layout.valign != '' && layout_classes.push(layout.valign)
                if (layout.valign_content || layout.align_content) {
                    // console.log(layout)
                    layout_classes.push('flex', layout.valign_content, layout.align_content,'flex-col')

                }
                layout.extra_classes_arr?.length && layout_classes.push(...layout.extra_classes_arr)
                layout.column.start && layout_classes.push(`col-start-${layout.column.start}`)
                layout.column.span && layout_classes.push(`col-span-${layout.column.span}`)
                layout.column.end && layout_classes.push(`col-end-${layout.column.end}`)
                layout.use_margin && layout_classes.push('use-margin')
                layout.use_padding && layout_classes.push('use-padding')
               
            }
            // console.log(layout_classes, animation_settings)
        }

        return layout_classes;
    }
    return {
        ...toRefs(state),
        getLayoutClasses,
        getAnimationSettings
    }
}